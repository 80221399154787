
.tableRowActions {
    position: absolute;
    z-index: 2;
    top: 9px;
    left: 48px;
    right: 16px;
    height: 40px;
    background: #fff;
    border: 1px dashed #FF5545;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    
}

.tableRowActions__title {
    margin-right: 16px;
}

.tableTopRowActions__item {
    margin-right: 12px;
}

