.imageUpload {
    position: relative;
    display: block;
}

.imageUpload_large .ant-upload-select {
    width: 122px !important;
    height: 122px !important;
}

.imageUpload_large .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
    width: 122px;
    height: 122px;
}

.imageUpload .ant-upload-list {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
}

.imageUpload .ant-upload-list:before {
    display: none;
}

.imageUpload .ant-upload-list-picture-card-container {
    width: 100%;
    height: auto;
}

.imageUpload__draggableListItem {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    cursor: move;
}

.is_dragging {
    z-index: 999 !important;
}

.imageUpload .ant-upload-list-item-container:before {
    display: none !important;
}

.imageUpload .ant-upload-list-item {
    position: absolute !important;
    width: 100%;
    height: 100%;
    border-radius: 8px !important;
    padding: 4px !important;
}

.imageUpload .ant-upload-list-item:before {
    width: calc(100% - 8px) !important;
    height: calc(100% - 8px) !important;
}

.imageUpload .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.imageUpload .ant-upload-select {
    padding: 8px;
}

.imageUpload .ant-upload-list-picture-card-container,
.imageUpload .ant-upload-list-picture-card .ant-upload-list-item,
.imageUpload .ant-upload.ant-upload-select-picture-card {
    /*width: 176px;
    height: 176px;*/
}


.ant-form-item-control.has-success .imageUpload .ant-upload.ant-upload-select-picture-card {
    height: 100%;
    margin: 0;
}

.imageUpload__btn {
    font-size: 24px;
    color: #5F6B78;
}

.imageUpload__btnText {
    margin-top: 2px;
    font-size: 12px;
    line-height: 1.125;
    color: #5F6B78;
}

.ant-form-item-control.has-success .imageUpload__btn {
    display: flex;
    align-items: center;
}

.ant-form-item-control.has-success .imageUpload__btnIcon {
    margin-right: 8px;
    font-size: 20px;
}

.ant-form-item-control.has-success .imageUpload__btnText {
    margin-top: 0;
    font-size: 16px;
    line-height: 1;
}