.ant-drawer .ant-drawer-header {
    padding: 16px 24px;
    border-bottom: 1px solid #e8ebf7;
}

.ant-drawer .ant-drawer-body {
    background: var(--bg-gray);
}

.ant-drawer .ant-drawer-footer {
    padding: 16px 24px;
    border-top: 1px solid #e8ebf7;
}
