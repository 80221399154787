.tableFooter {
    position: sticky;
    bottom: -24px;
    margin: 0;
    padding: 0 16px;
    border-radius: 0 0 16px 16px;

    display: flex;
    align-items: center;

    height: 50px;
    background: #fff;
}

.tableFooter__stat {
    flex: 1;
    font-size: 12px;
    line-height: 24px;
    color: #888f9b;
}


.tableFooter .ant-pagination {
    margin-bottom: 0 !important;
}

.tableFooter .ant-pagination-prev {
    min-width: 24px;
    margin-right: 6px;
    height: 24px;
    line-height: 24px;
}

.tableFooter .ant-pagination-next {
    min-width: 24px;
    height: 24px;
    line-height: 24px;
}

.tableFooter .ant-pagination-prev .ant-pagination-item-link,
.tableFooter .ant-pagination-next .ant-pagination-item-link {
    border: 0;
    font-size: 12px;
    height: 24px;
    border-radius: 6px;
}

.tableFooter .ant-pagination-item,
.tableFooter .ant-pagination-total-text {
    min-width: 24px;
    height: 24px;
    line-height: 22px;
    margin-right: 6px;
    font-size: 12px;
    border-radius: 6px;
}