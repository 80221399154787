.switch .ant-form-item-row1 {
    display: flex;
    align-items: center !important;
    flex-flow: row nowrap !important;
}

.switch .ant-form-item-label1 {
    position: static !important;
    width: auto;
    transform: none;
    padding: 0 !important;
    margin-right: 8px;
}

.switch .ant-form-item-control1 {
    width: auto !important;
}