.siteDropdown {
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    height: 42px;
    border-radius: 6px;
    color: var(--primary-color);
    background: var(--primary-color-1);
    border: 1px solid var(--primary-color-4);

    display: flex;
    align-items: center;
    justify-content: center;
}

.siteDropdownCurrent {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 20px 0 8px;
    max-width: 204px;
}

.siteDropdownCurrent__caret {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
}
