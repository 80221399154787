.actionGroup {
    display: flex;
    justify-content: flex-end;
}

.actionGroup_combined .ant-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -1px;
    border-radius: 0;
    border-color: var(--border-color-light);
    background: var(--default-control-bg);
}

.actionGroup_combined .ant-btn-sm {
    padding: 0 10px;
    min-width: 29px;
    font-size: 12px;
}

.actionGroup_combined .ant-btn-dangerous {
    border-color: #d9d9d9;
}

.actionGroup_combined .ant-btn:hover {
    z-index: 2;
    color: #fff !important;
    background: var(--ant-primary-color-hover) !important;
}

.actionGroup_combined .ant-btn:active {
    z-index: 2;
    color: #fff !important;
    background: var(--ant-primary-color-active) !important;
}

.actionGroup_combined .ant-btn:first-child {
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
}

.actionGroup_combined .ant-btn:last-child {
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
}


.actionGroup_isSingle .ant-btn {
    padding: 0 16px;
}


.actionGroup_separated {
    gap: 24px;
}

.actionGroup_separated > .anticon {
    font-size: 24px;
}

.actionGroup .action__component {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--light-color)
}
