.appNavigation.ant-menu {
    border-right: 0 !important;
    user-select: none;

    font-family: 'Open Sans', sans-serif;
    font-size: 9px;
    font-weight: 500;

    background: transparent;
}

.appNavigation.ant-menu.fixed {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.appNavigation.ant-menu.fixed .ant-menu-item,
.appNavigation.ant-menu.fixed .ant-menu-submenu-title {
    height: 56px;
    color:  var(--sider-color);
}
.appNavigation.ant-menu.fixed .ant-menu-title-content {
    opacity: 1 !important;
    display: block;
    width: 64px;
    text-align: center;
    min-height: 10px;
    margin: 8px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.appNavigation .ant-avatar {
    min-width: 30px;;
    min-height: 30px;
}

.appNavigation.ant-menu .ant-menu-item,
.appNavigation.ant-menu .ant-menu-submenu-title {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    width: calc(100% - 16px);
    height: 56px;
    line-height: 1;
    padding: 8px;
    margin: 8px;

    color:  var(--sider-color);
}

.appNavigation.ant-menu .ant-menu-item-icon {
    margin: 0 0 8px;
    font-size: 24px !important;
    min-width: 24px;
}

.appNavigation.ant-menu .ant-menu-item .ant-menu-title-content {
    display: block;
    opacity: 1;
    margin: 0;
    line-height: 1.125;
}

.appNavigation.ant-menu .ant-menu-item:hover,
.appNavigation.ant-menu .ant-menu-submenu-title:hover {
    color: var(--primary-color) !important;
    background: transparent !important;
}

.appNavigation1 .ant-menu-item:active,
.appNavigation1 .ant-menu-submenu-title:active {
    background: transparent
}

.appNavigation.ant-menu .ant-menu-item-selected,
.appNavigation.ant-menu .ant-menu-submenu-selected .ant-menu-submenu-title {
    color: #fff;
    background: var(--primary-color);
}

.appNavigation.ant-menu .ant-menu-item-selected:hover,
.appNavigation.ant-menu .ant-menu-submenu-selected:hover .ant-menu-submenu-title {
    color: #fff !important;
    background: var(--primary-color-hover) !important;
}