.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;

}

.panel {
    width: 100%;
    max-width: 350px;
    padding: 35px 50px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .15);
}

.title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    color: #5F6B78;
}