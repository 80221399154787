.table {
    position: relative;
}

.table .ant-table {
    background: #fff;
    color: var(--default-color);
    font-size: 14px;
    line-height: 1.125;
    overflow: hidden;
    border-bottom: 1px solid var(--border-color);
}

.table .ant-table table {
    border-collapse: collapse;
    /*border-spacing: 0 5px;*/
}

.table .ant-table .ant-table-header,
.table .ant-table .ant-table-thead>tr>th {
    color: var(--light-color);
    font-size: 13px;
    font-weight: 400;
}

.table .ant-table .ant-table-thead .ant-table-cell {
    background: transparent;
    border-bottom: 1px solid var(--border-color);
    padding: 16px 12px;
}

.table .ant-table-wrapper .ant-table-cell:first-child {
    padding-left: 16px !important;
    border-bottom: 0;
}

.table .ant-table-wrapper .ant-table-cell:last-child {
    padding-right: 16px !important;
    border-bottom: 0;
}

.table .ant-table-wrapper .ant-table-cell:first-child:after,
.table .ant-table-wrapper .ant-table-cell:last-child:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid var(--border-color-light);
}
.table .ant-table-wrapper .ant-table-thead .ant-table-cell:first-child:after,
.table .ant-table-wrapper .ant-table-thead .ant-table-cell:last-child:after {
    bottom: -1px;
    border-bottom: 1px solid var(--border-color);
}
.table .ant-table-wrapper .ant-table-cell:first-child:after {
    left: 16px;
}
.table .ant-table-wrapper .ant-table-cell:last-child:after {
    right: 16px;
}

.table .ant-table .ant-table-tbody>tr>td {
    padding: 16px 12px;
    border-bottom: 1px solid var(--border-color-light);
}

.table .ant-table .ant-table-tbody>tr:last-child>td,
.table .ant-table .ant-table-tbody>tr:last-child>td:after {
    border-bottom: 0;
}

.table .ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected >.ant-table-cell {
    background: var(--ant-hover-color);
}

.table .ant-table-tbody>tr:hover>td {
    background: var(--ant-hover-color);
}

.table .ant-table-tbody>tr.ant-table-row:hover>td,
.table .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: var(--ant-hover-color);
}

.table .ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: transparent;
    color: var(--ant-primary-color);
}

.table .ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
    background: transparent;
}

.table .ant-table-wrapper td.ant-table-column-sort {
    background: var(--ant-hover-color);
}

.table .ant-table-scroll {
    overflow: auto;
    overflow-x: hidden;
}
