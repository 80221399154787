.htmlText {
    
}

.htmlText__text {
    overflow-x: hidden;
}

.htmlText_big .htmlText__text {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.htmlText__more {
    display: inline-block;
    cursor: pointer;
    margin-top: 4px;
    color: var(--ant-primary-color);
}