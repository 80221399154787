.ant-btn-lg {
  min-width: 36px;
  height: 37px;
  font-size: 14px;
}

.ant-btn-primary {
  color: #fff;
  border-color: var(--ant-primary-color);
  background: var(--ant-primary-color);
}

.ant-btn-primary:hover {
  color: #fff;
  border-color: var(--ant-primary-color) !important;
  background: var(--ant-primary-color-hover) !important;
}

.ant-btn-primary:active {
  color: #fff;
  border-color: var(--ant-primary-color-active) !important;
  background: var(--ant-primary-color-active) !important;
}

.ant-btn-default {
  color: var(--defult-color);
}

.ant-btn-default:hover {
  color: var(--ant-primary-color) !important;
  border-color: var(--ant-primary-color) !important;
}

.ant-btn-default:active {
  color: var(--ant-primary-color-active) !important;
  border-color: var(--ant-primary-color-active) !important;
}

.ant-btn-danger {
  color: #fff;
  border-color: var(--ant-error-color);
  background: var(--ant-error-color);
}

.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #DBDCDB;
  border-radius: 2em 0 0 2em;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 2em 2em 0;
}


.ant-radio-group-small label {
  font-size: 12px;
}

.ant-form-item-control-input {
  min-height: 40px !important;
}

.ant-input-affix-wrapper-lg {
  padding: 5px 11px;
  font-size: 14px;
}

.ant-picker-large {
  padding: 5px 11px;
}


.ant-tabs-tab-btn,
.ant-tabs-tab-remove {
  outline: none;
  transition: all .1s;
}

.ant-tabs-bottom>.ant-tabs-nav:before,
.ant-tabs-bottom>div>.ant-tabs-nav:before,
.ant-tabs-top>.ant-tabs-nav:before,
.ant-tabs-top>div>.ant-tabs-nav:before {
  border-bottom: 1px solid var(--border-color);
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  display: block;
  height: 10px;
  margin-top: -1px;
}


.tabsForm .ant-tabs-nav {
  background: #fff;
  margin: 0;
}

.tabsForm .ant-tabs-tab-btn {
  font-weight: 600;
  color: #666F7C;
}

.tabsForm .ant-tabs-tab {
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid transparent !important;
  background: transparent !important;
  border-radius: 8px 8px 0 0 !important;
}

.tabsForm .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #0097EB !important;
}


.tabsForm .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0097EB;
}

.tabsForm.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.tabsForm.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
  background: #f7f7f7 !important;
  border: 1px solid #e0e0e0 !important;
  border-bottom-color: #f7f7f7 !important;
}


.ant-form .ant-collapse {
  background: transparent;
}

.ant-form .ant-collapse>.ant-collapse-item {
  border: 0;
}

.ant-form .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 12px 0;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
  border-bottom: 1px solid var(--border-color);
  align-items: center;
}

.ant-form .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 16px 0;
}

.ant-form .ant-collapse-item.required>.ant-collapse-header .ant-collapse-header-text:after {
  position: relative;
  top: 2px;
  display: inline-block;
  margin-inline-start: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-form .ant-collapse-extra {
  line-height: 1;
}

.ant-form .ant-collapse-extra .anticon {
  font-size: 16px;
}