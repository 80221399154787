.boolValue {
    font-size: 16px;
    color: #ff4d4f;
}

.boolValue_isTrue {
    color: #70AE5B;
}

.boolValue {

}
