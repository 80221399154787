.breadcrumbs {
    height: 20px;
    font-size: 10px;
    line-height: 30px;
    color: #868F9E;
    fill: #868F9E;
    overflow: hidden;
}

.breadcrumbsInner {
    padding: 0 0 20px 0;
    overflow-x: auto;
}

.breadcrumbsList {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.breadcrumbsItem {
    display: flex;
    align-items: center;
    height: 20px;
}

.breadcrumbsItem a {
    display: block;
    line-height: 1.125;
    color: #868F9E;
}

.breadcrumbsItem:after {
    display: block;
    content: "";
    margin: 0 6px 0 4px;
    width: 5px;
    height: 5px;
    border: 1px solid #868F9E;
    border-bottom: 0;
    border-left: 0;
    transform-origin: 50% 50%;
    transform: rotate(45deg);
}
