.siteNavigation.ant-menu {
    padding-bottom: 36px;
    border-right: 0 !important;
    user-select: none;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;

    background: transparent;
}

.siteNavigation.ant-menu .ant-menu-item-group {
    padding: 0 24px;
}

.siteNavigation.ant-menu .ant-menu-item-group-list>.ant-menu-item {
    padding-left: 12px !important;
}

.siteNavigation.ant-menu .ant-menu-item-divider {
    margin: 8px 0;
    border-color: transparent;
}

.siteNavigation.ant-menu .ant-menu-item .ant-menu-item-icon+span {
    margin-inline-start: 16px;
}

.siteNavigation .ant-menu-item-group-title {
    text-transform: uppercase;
    letter-spacing: .01em;
    font-size: 10px;
    line-height: 16px;
    padding: 6px 0 0;
    color: var(--light-color);
}



.siteNavigation .ant-menu-submenu-title {
    padding: 0 18px !important;
}

.siteNavigation .ant-menu-item-only-child1 {
    padding: 0 18px 0 40px !important;
}

.siteNavigation.ant-menu .ant-menu-item-icon {
    position: relative;
    z-index: 2;
    font-size: 16px;

}

.siteNavigation .ant-menu-title-content {
    position: relative;
    z-index: 2;
}


.siteNavigation .ant-menu-sub.ant-menu-inline {
    background: #fff !important;
}

.siteNavigation.ant-menu .ant-menu-sub.ant-menu-inline>.ant-menu-item {
    padding: 0 18px 0 43px !important;
}


.siteNavigation .ant-menu-item:active,
.siteNavigation .ant-menu-submenu-title:active {
    background: transparent
}


.appSider.ant-layout-sider-collapsed .siteNavigation .ant-menu-item {
    padding: 0 26px !important;
}

.appSider.ant-layout-sider-collapsed .siteNavigation .ant-menu-item-group {
    padding: 0 8px;
    text-align: center;
}

.appSider.ant-layout-sider-collapsed .siteNavigation .ant-menu-item-group-title {
    font-size: 8px;
}


.siteNavigation.ant-menu-light .ant-menu-item {
    color: var(--sider-color2);
}

.siteNavigation.ant-menu-light .ant-menu-item-selected {
    color: var(--ant-primary-color);
    background: transparent;
}