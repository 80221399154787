.error {
    margin-bottom: 15px;
    padding: 8px 10px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    /*background: linear-gradient(30deg, rgb(240, 103, 168) 0%, rgb(247, 149, 80) 100%);*/
    background: linear-gradient(30deg, #FA3C71 0%, #F7642F 100%);
}
