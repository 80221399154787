.advertValue {
    display: flex;
    align-items: center;
}

.advertValue__photo {
    min-width: 64px;
    margin-right: 8px;
    color: var(--default-color);
    background: var(--bg-gray);
}

.advertValue__photo .anticon {
    font-size: 48px;
    color: var(--light-color);
}

.advertValue__name {
    min-width: 0;
    font-weight: 500;
}

.advertValue__name>span,
.advertValue__name>div {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}
