.page {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 0;
    height: 100vh;
    background: var(--bg-gray) !important;
    overflow: hidden;
}

.pageContent {
    height: calc(100vh - 60px);
    padding: 24px 24px;
    overflow-y: scroll;
}

.page__witTabs .pageContent {
    height: calc(100vh - 80px);
    padding-top: 36px;
}