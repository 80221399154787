.details {
    position: relative;
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 24px;
    border: 1px solid var(--border-color);
}

.details_withHeader {
    padding-top: 0;
}

.container:last-child {
    margin-bottom: 0;
}


.details__header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    height: 48px;
    padding: 4px 0;
    border-bottom: 1px solid var(--border-color)
}

.details__headerMain {
    flex: 1;
}

.details__title {
    letter-spacing: .4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.125;
    color: var(--defult-color);
}

.details__content {}

/********/
.detailsItem__wrap {
    position: relative;
    display: flex;
    padding: 4px 0;
}

.detailsItem__label {
    width: 102px;
    margin-right: 8px;
    padding-top: 2px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.125;
    color: var(--light-color);

}

.detailsItem__label:after {
    content: ':';
}

.detailsItem__content {
    flex: 1;
    max-width: 100%;
    color: var(--default-color);
    font-size: 14px;
    font-weight: 500;
}

.detailsItem__content img {
    max-width: 100%;
}

.detailsItem__content_empty:after {
    content: '-';
}

.detailsItem_small .detailsItem__label {
    font-size: 13px;
}

.detailsItem_small .detailsItem__content {
    font-size: 14px;
}

.detailsItem_block {
    gap: 8px;
}

.detailsItem_block .detailsItem__wrap {
    display: block;
    min-height: 44px;
    padding: 0;
}

.detailsItem_block .detailsItem__label {
    width: auto;
    margin-right: 0;
    margin-bottom: 6px;
    padding-top: 0;
    font-size: 13px;
}