.siteValue {
    display: flex;
    align-items: center;
}

.siteValue__icon {
    margin-right: 8px;
    color: var(--default-color);
    background: #fff;
}

.siteValue__icon .anticon {
    font-size: 18px;
}

.siteValue__name {
    min-width: 0;
    font-weight: 500;
}

.siteValue__name > span, 
.siteValue__name > div {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}