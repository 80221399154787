.filterRange {
    width: 220px;
    border-radius: 8px !important;
    border-color: var(--border-color) !important;
}

.filterRange .ant-picker-range-separator {
    padding: 0 4px 0 0;
}

.filterRange.ant-picker-large .ant-picker-input>input {
    font-size: 14px;
    line-height: 25px;

}