/*@import '~antd/dist/antd.css';*/
@import "./antdReset.css";

:root {
  --defult-color: #0d0a19;
  --light-color: #8d99a9;

  --primary-color: #6C5DD3;
  --primary-color-hover: #877bdb;
  --primary-color-active: #564aa8;

  --primary-color-1: #dbd8f4;
  --primary-color-2: #bfb9ec;
  --primary-color-3: #a39ae3;
  --primary-color-4: #877bdb;
  --primary-color-5: #6C5DD3;
  --primary-color-6: #564aa8;

  --sider-color: #929db1;
  --sider-bg-color: #292a49;

  --sider-color2: #2b3451;
  --sider-bg-color2: #f9fafc;

  --border-color: #dce0e3;
  --border-color-light: #ebeff2;

  --default-control-bg: #f5f6f7;
  --bg-gray: #f7f7f8;

  --ant-hover-color: #e6f4ff;

  --ant-primary-color: #006fff;
  --ant-primary-color-hover: #308aff;
  --ant-primary-color-active: #0058cc;
  --ant-primary-color-outline: #c1dcff;

  --ant-success-color: #1aaf56;
  --ant-success-color-hover: #45be76;
  --ant-success-color-active: #148c44;
  --ant-success-color-outline: #9cdcb6;

  --ant-error-color: #ff4d4f;
  --ant-error-color-hover: #ff7875;
  --ant-error-color-active: #d9363e;
  --ant-error-color-outline: rgba(255, 77, 79, 0.2);

  --ant-warning-color: #faad14;
  --ant-warning-color-hover: #ffc53d;
  --ant-warning-color-active: #d48806;
  --ant-warning-color-outline: rgba(250, 173, 20, 0.2);

  --ant-info-color: #D5605C;
}

body {
  /*font-family: Helvetica, Arial, sans-serif;*/
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;

  font-weight: 400;
  line-height: 1.375;
  /*color: #414954;
      fill: #414954;*/
  color: var(--defult-color);
  margin: 0;
  padding: 0;
  background: #fff;
  /*user-select: none;*/

  /*text-rendering: optimizeLegibility;*/
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 0;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px;
  width: 30px;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 16px;
  width: 16px;
}

.image-gallery-slide .image-gallery-image {
  object-fit: cover;
}

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  width: 58px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  object-fit: cover;
}

.image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
  object-fit: contain;
}