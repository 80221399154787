.advertStatuses {
    margin-top: 8px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 4px 4px;
    font-size: 1em;

    color: var(--ant-success-color);
    fill: var(--ant-success-color);
    border: 1px solid var(--border-color);
    border-radius: 2em;
}

.advertStatuses>div {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
}

.advertStatuses .icon {
    position: relative;
    top: 1px;
    font-size: 1.1em;
}