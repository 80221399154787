.userValue {
    display: flex;
    align-items: center;
}

.userValue__icon {
    margin-right: 8px;
    color: var(--primary-color-6);
    background: var(--primary-color-1);
}

.userValue__icon .anticon {
    font-size: 16px;
}

.userValue__name {
    min-width: 0;
    font-weight: 500;
    line-height: 1.25;
}

.userValue__name > span, 
.userValue__name > div {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}