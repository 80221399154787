.pageHeader {
    position: relative;
    z-index: 2;
    padding: 0 24px;
    width: 100%;
    height: 60px;
    background: #fff;
    border-bottom: 1px solid var(--border-color);
}

.page__witTabs .pageHeader {
    height: 80px;
}

.pageHeaderMain {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0;
    height: 60px;
}

.pageHeader__start {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 0;
}

.pageHeader__middle {
    flex: 1;
    display: flex;
    align-items: center;
}

.pageHeader__end {
    display: flex;
    align-items: center;
}

.pageHeaderTitle {
    margin-right: 16px;
    flex: 1;
    min-width: 0;
}

.pageHeaderTitle>div {
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pageHeaderInfo {
    margin-top: 8px;
    font-size: 14px;
    color: var(--light-color);
}

.pageHeader .backBtn {
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    color: #000;

    padding: 4px;
    background: var(--border-color-light);
    transition: all .3s;
}

.pageHeader .backBtn:hover {
    color: #fff;
    background: var(--ant-primary-color-hover);
}