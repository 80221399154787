.image {
    padding: 0;
    position: relative;
    border-radius: 8px;
}

.image__withRatio .imageInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.imageInner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;
    border-radius: 8px;
}

.imageInner img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

.imageEmpty {
    text-align: center;
    color: #666;
    font-size: 12px;
}


.imageEmpty .anticon {
    font-size: 1.5em;
}

.imageHover {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .25);

    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease;
}

.image:hover .imageHover {
    visibility: visible;
    opacity: 1;
}

.imagePreviewBtn {
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}